/** @jsxImportSource theme-ui */

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Title = ({ children, ...props }: Props) => {
  return (
    <h1
      {...props}
      sx={{
        fontSize: ["lg", "xl", "xxl", null, "xxxxl"],
        fontWeight: "extraBold",
        lineHeight: "xs",
      }}
    >
      {children}
    </h1>
  );
};

export default Title;
