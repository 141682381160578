/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import Copy from "./Copy";
import ProjectTechnologies from "./ProjectTechnologies";
import { Project } from "../data/projects";

type Props = {
  project: Project;
  px?: any;
};

const ProjectResponsibilities = ({ project, px }: Props) => {
  return (
    <Flex as="section" sx={{ flexDirection: ["column", null, "row"] }}>
      <Copy
        hasHTML
        sx={{
          alignItems: [null, null, "center"],
          display: [null, null, "flex"],
          flex: [null, null, 2],
          py: [3, null, 5],
          pt: [0, null],
          px,
        }}
      >
        {project.responsibilities}
      </Copy>
      <ProjectTechnologies project={project} px={px} />
    </Flex>
  );
};

export default ProjectResponsibilities;
