/** @jsxImportSource theme-ui */
import ProjectExcerpt from "./ProjectExcerpt";
import ProjectHeader from "./ProjectHeader";
import ProjectHighlights from "./ProjectHighlights";
import ProjectImage from "./ProjectImage";
import ProjectNavigation from "./ProjectNavigation";
import ProjectResponsibilities from "./ProjectResponsibilities";
import ProjectSummary from "./ProjectSummary";
import ProjectVideo from "./ProjectVideo";
import { Project } from "../data/projects";

// Styles
const py = [3, null, 5, null, 6];
const px = [2, null, 3, null, 6];
const maxWidth = "65ch";

type Props = {
  project: Project;
};

const PageProject = ({ project }: Props) => {
  return (
    <article
      sx={{
        height: "100%",
        "& ::selection": {
          bg: project.color,
          color: "white",
        },
      }}
    >
      <ProjectHeader project={project} />
      {project.image && <ProjectImage image={project.image} />}
      <ProjectExcerpt excerpt={project.excerpt} px={px} py={py} />
      <ProjectSummary project={project} maxWidth={maxWidth} px={px} py={py} />
      <ProjectResponsibilities project={project} px={px} />
      {project.video && <ProjectVideo video={project.video} />}
      {project.highlights && (
        <ProjectHighlights project={project} maxWidth={maxWidth} px={px} py={py} />
      )}
      <ProjectNavigation project={project} px={px} />
    </article>
  );
};

export default PageProject;
