/** @jsxImportSource theme-ui */
import { useRef, useCallback, useEffect, useState } from "react";
import { Flex } from "theme-ui";
import { useSpring, animated as a, config, to } from "react-spring";
import Copy from "./Copy";
import ProjectIcon from "./ProjectIcon";
import { Project } from "../data/projects";

type Props = {
  project: Project;
  px?: any;
};

const ProjectTechnologies = ({ project, px }: Props) => {
  // Ref
  const containerRef = useRef(null);
  const [mainHeight, setMainHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  // Spring creation
  const [{ y }, api] = useSpring(() => ({
    config: { ...config.default, clamp: true },
    y: 0,
  }));

  // On mount
  useEffect(() => {
    const main: HTMLElement = document.querySelector("#main");
    if (main) {
      main.addEventListener("scroll", onScroll);
      setMainHeight(main.offsetHeight);
      setContainerHeight(containerRef?.current.offsetHeight);

      return () => {
        main.removeEventListener("scroll", onScroll);
        api.stop();
      };
    }
  }, []);

  // Callbacks
  const onScroll = useCallback(() => {
    api.start({ y: containerRef.current.getBoundingClientRect().top });
  }, []);

  // Spring interpolations
  const range = [mainHeight - containerHeight, mainHeight];

  return (
    <aside
      ref={containerRef}
      sx={{
        flex: [null, null, 1],
        position: "relative",
        px,
        py: [3, null, 5],
        zIndex: 0,
      }}
    >
      <a.div
        style={{
          transform: to(
            [
              y.to({ extrapolate: "clamp", range, output: [1, 0.75] }),
              y.to({ extrapolate: "clamp", range, output: [1, 1.5] }),
              y.to({ extrapolate: "clamp", range, output: [0, -16] }),
            ],
            (sx, sy, ty) => `scaleX(${sx}) scaleY(${sy}) translateY(${ty}%)`,
          ),
        }}
        sx={{
          bg: "black",
          bottom: 0,
          left: 0,
          pointerEvents: "none",
          position: "absolute",
          right: 0,
          top: 0,
          transformOrigin: "top left",
          willChange: "transform",
          zIndex: -1,
        }}
      />
      <a.div
        style={{
          opacity: y.to({
            extrapolate: "clamp",
            range,
            output: [1, 0],
          }),
        }}
      >
        <Copy sx={{ color: "white", fontWeight: "bold", mb: 2 }}>Technologies used</Copy>
        <ul sx={{ color: "white" }}>
          {project.technologies.map((technology, i) => {
            return (
              <Flex
                as="li"
                key={i}
                sx={{
                  alignItems: "center",
                  "&:not(:last-child)": { mb: 2 },
                }}
              >
                <ProjectIcon
                  icon={technology.icon}
                  sx={{
                    height: "32px",
                    mr: [2, null, null, null, 3],
                    width: "32px",
                  }}
                />
                <Copy
                  sx={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                  }}
                >
                  {technology.text}
                </Copy>
              </Flex>
            );
          })}
        </ul>
      </a.div>
    </aside>
  );
};

export default ProjectTechnologies;
