/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import { Flex } from "theme-ui";
import Copy from "./Copy";
import H3 from "./H3";
import Video from "./Video";
import { Project } from "../data/projects";

type Props = {
  maxWidth?: string;
  project: Project;
  px?: any;
  py?: any;
};

const ProjectHighlights = ({ project, maxWidth, px, py }: Props) => {
  type Highlight = typeof project.highlights[number];

  return (
    <Fragment>
      {project.highlights.map((highlight: Highlight, i: number) => {
        const even = i % 2 === 0;
        const transformMobile = even ? `translate3d(-15%, -25%, 0)` : `translate3d(-85%, -25%, 0)`;
        const transformDesktop = even ? `translate3d(-15%, -50%, 0)` : `translate3d(-85%, -50%, 0)`;

        return (
          <Flex
            as="article"
            key={i}
            sx={{
              flexDirection: "column",
              position: "relative",
              px,
              py,
              pt: [5, null, 6],
              zIndex: 0,
              "&:not(:last-child)": {
                mb: [null, null, 5],
              },
            }}
          >
            <p
              sx={{
                color: "white",
                fontSize: [256, null, 512],
                fontWeight: "extraBold",
                left: even ? 0 : "100%",
                pointerEvents: "none",
                position: "absolute",
                top: [0, null, 6],
                transform: [transformMobile, null, transformDesktop],
                zIndex: -1,
              }}
            >
              0{i + 1}
            </p>
            <Flex
              sx={{
                alignItems: [null, null, "center"],
                flex: [0, 0, 1],
                flexDirection: ["column", null, even ? "row" : "row-reverse"],
                height: ["auto", null, "100%"],
              }}
            >
              <div
                sx={{
                  flex: [0, 0, 1],
                  mb: [3, null, 0],
                  maxWidth,
                  ml: [0, null, even ? null : 3, null, even ? null : 5],
                  mr: [0, null, even ? 3 : null, null, even ? 5 : null],
                }}
              >
                <H3 sx={{ mb: 3 }}>{highlight.title}</H3>
                <Copy
                  hasHTML
                  sx={{
                    "& a": {
                      color: "text",
                    },
                  }}
                >
                  {highlight.copy}
                </Copy>
              </div>
              {(highlight.video || highlight.image) && (
                <aside
                  sx={{
                    flex: [0, 0, 1],
                    ml: [0, null, even ? 3 : null, null, even ? 5 : null],
                    mr: [0, null, even ? null : 3, null, even ? null : 5],
                  }}
                >
                  {highlight.image && (
                    <img {...highlight.image} loading="lazy" sx={{ width: "100%" }} />
                  )}
                  {highlight.video && (
                    <Video
                      {...highlight.video}
                      sx={{ height: "auto", maxHeight: 600, width: "auto" }}
                    />
                  )}
                </aside>
              )}
            </Flex>
          </Flex>
        );
      })}
    </Fragment>
  );
};

export default ProjectHighlights;
