/** @jsxImportSource theme-ui */
import { useRef, useEffect } from "react";
import { useIntersection } from "react-use";

type Props = {
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  poster?: string;
  src: string;
};

const Video = (props: Props) => {
  const { autoPlay, ...rest } = props;

  // Intersection Observer
  // (as soon as the video becomes SLIGHTLY visible, load it!)
  const videoRef = useRef(null);
  const threshold = 0.1;
  const intersection = useIntersection(videoRef, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  // On "inView" change
  useEffect(() => {
    if (inView && autoPlay) {
      videoRef.current.play();
    }
  }, [inView]);

  return (
    <video
      {...rest}
      preload="metadata"
      ref={videoRef}
      sx={{
        height: "100%",
        minHeight: 260,
        objectFit: "contain",
        width: "100%",
        "&:focus": {
          outline: "none",
        },
      }}
    />
  );
};

export default Video;
