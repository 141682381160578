/** @jsxImportSource theme-ui */
import H2 from "./H2";

type Props = {
  excerpt: string;
  px?: any;
  py?: any;
};

const ProjectExcerpt = ({ excerpt, px, py }: Props) => {
  return <H2 sx={{ maxWidth: "32ch", px, py, mb: [2, null, 0] }}>{excerpt}</H2>;
};

export default ProjectExcerpt;
