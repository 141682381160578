/** @jsxImportSource theme-ui */
import Video from "./Video";

type Props = {
  video: any;
};

const ProjectVideo = ({ video }: Props) => {
  return (
    <section sx={{ bg: "white", height: "100%", position: "relative", zIndex: 1 }}>
      <Video {...video} />
    </section>
  );
};

export default ProjectVideo;
