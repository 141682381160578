/** @jsxImportSource theme-ui */
import { useState } from "react";
import { Flex, useThemeUI } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useSpring, animated as a, config } from "react-spring";
import { darken } from "polished";
import ProjectIcon from "./ProjectIcon";
import TransitionLink from "./TransitionLink";
import projects, { Project } from "../data/projects";

type Props = {
  project: Project;
  px?: any;
};

const ProjectNavigation = ({ project, px }: Props) => {
  const { theme } = useThemeUI();
  const [isHovered, setIsHovered] = useState(false);

  // Find the next project
  const nextProject = projects.find((p) => p.id === project.next);
  if (!nextProject) return null;

  // Accessing theme values
  const space = useResponsiveValue((theme) => [0, null, theme.space[3]]);
  const color = theme.colors[nextProject.color];
  const projectColor = typeof color === "object" ? color[0] : color;
  const darkenedColor = darken(0.1, projectColor);

  // Spring creation
  const { backgroundColor, opacity, transform, x } = useSpring({
    config: config.gentle,
    backgroundColor: isHovered ? projectColor : darkenedColor,
    opacity: isHovered ? 0.4 : 0.25,
    transform: isHovered ? "scaleY(1.1)" : "scaleY(1)",
    x: isHovered ? 0 : space,
  });

  return (
    <TransitionLink
      data-no-cursor
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ display: "block", position: "relative", textDecoration: "none" }}
      to={nextProject.slug}
      title={`Next project: ${nextProject.name}`}
    >
      <Flex
        as="span"
        sx={{
          color: "white",
          fontSize: ["md", null, "xl"],
          fontWeight: "bold",
          justifyContent: "center",
          mx: 3,
          position: "relative",
          px,
          py: [5, null, null, null, 6],
          textAlign: "center",
          zIndex: 1,
        }}
      >
        Next project
      </Flex>
      <a.span
        style={{ backgroundColor, left: x, right: x, transform }}
        sx={{
          bottom: 0,
          position: "absolute",
          top: 0,
          transformOrigin: "bottom center",
          willChange: "background-color, left, right, transform",
          zIndex: 0,
        }}
      />
      <a.span
        style={{ opacity }}
        sx={{
          bottom: 3,
          height: (theme) => `calc(100% - ${Number(theme.space[3]) * 2}px)`,
          left: "50%",
          overflow: "hidden",
          position: "absolute",
          right: "50%",
          textAlign: "center",
          transform: "translateX(-50%)",
          width: "25%",
        }}
      >
        <ProjectIcon icon={nextProject.icon} sx={{ height: "100%" }} />
      </a.span>
    </TransitionLink>
  );
};

export default ProjectNavigation;
