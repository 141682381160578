import Page from "../components/Page";
import PageProject from "../components/PageProject";
import SEO from "../components/SEO";

const ProjectPage = (props: any) => {
  const {
    pageContext: { project },
    transitionStatus,
    uri,
  } = props;

  return (
    <Page transitionStatus={transitionStatus} uri={uri}>
      <SEO title={`${project.name} project`} />
      <PageProject project={project} />
    </Page>
  );
};

export default ProjectPage;
