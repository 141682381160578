/** @jsxImportSource theme-ui */
import { useEffect, useRef } from "react";
import { Flex, useThemeUI } from "theme-ui";
import { useIntersection } from "react-use";
import { useTrail, animated as a, config } from "react-spring";
import { darken } from "polished";
import H3 from "./H3";
import ProjectIcon from "./ProjectIcon";
import Title from "./Title";
import { transitionTimes } from "../utils/transitions";
import { Project } from "../data/projects";

type Props = {
  project: Project;
};

const ProjectHeader = ({ project }: Props) => {
  const { theme } = useThemeUI();

  // Colors management (appease TS)
  const color = theme.colors[project.color];
  const bgColor = darken(0.05, typeof color === "object" ? color[0] : color);

  // Intersection Observer
  const headerRef = useRef(null);
  const threshold = 0.5;
  const intersection = useIntersection(headerRef, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  // Spring creation
  const [trail, api] = useTrail(2, () => ({
    config: { ...config.slow, clamp: true },
    opacity: 0,
    transform: "translateY(2vh)",
  }));

  // On mount / unMount
  useEffect(() => {
    return () => {
      api.stop();
    };
  }, []);

  // When "inView" changes, trigger the animation
  useEffect(() => {
    if (inView) {
      api.start({
        delay: transitionTimes.pages,
        opacity: 1,
        transform: "translateY(0%)",
      });
    }
  }, [inView]);

  return (
    <Flex
      as="header"
      ref={headerRef}
      sx={{
        bg: bgColor,
        height: "100%",
        flexDirection: ["column", null, "row"],
        px: [2, null, 5, null, 6],
        "& ::selection": {
          bg: "white",
          color: project.color,
        },
        py: (theme) => [Number(theme.sizes[4]) + Number(theme.space[2]) * 2, null, 0],
      }}
    >
      <Flex
        sx={{
          flex: 1,
          flexDirection: "column",
          justifyContent: [null, null, "center"],
          mb: [2, null, 0],
        }}
      >
        {trail.map(({ opacity, transform }, i) => {
          const style = { opacity, transform };

          switch (i) {
            case 0: {
              const Comp = a(H3);
              return (
                <Comp key={i} style={style} sx={{ color: "white", mb: [1, null, 2] }}>
                  {project.client}
                </Comp>
              );
            }

            case 1: {
              const Comp = a(Title);
              return (
                <Comp key={i} style={style} sx={{ color: "white" }}>
                  {project.name}
                </Comp>
              );
            }
          }
        })}
      </Flex>
      <a.div
        style={{ opacity: trail[trail.length - 1].opacity }}
        sx={{
          alignItems: "center",
          display: "flex",
          flex: [2, null, 1],
          flexDirection: "column",
          justifyContent: "center",
          willChange: "opacity",
        }}
      >
        <ProjectIcon icon={project.icon} sx={{ height: "80%", p: [null, null, 3], width: "80%" }} />
      </a.div>
    </Flex>
  );
};

export default ProjectHeader;
