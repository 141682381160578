/** @jsxImportSource theme-ui */

type Props = {
  image: {
    alt?: string;
    src: string;
  };
};

const ProjectImage = ({ image }: Props) => {
  return <img {...image} sx={{ width: "100%" }} />;
};

export default ProjectImage;
