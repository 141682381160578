/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import Copy from "./Copy";
import ButtonLink from "./ButtonLink";
import { Project } from "../data/projects";

type Props = {
  maxWidth?: string;
  project: Project;
  px?: any;
  py?: any;
};

const ProjectSummary = ({ maxWidth, project, px, py }: Props) => {
  return (
    <Flex as="section" sx={{ flexDirection: ["column", null, "row"], px, pb: py }}>
      <Copy
        sx={{
          fontWeight: "bold",
          flex: [null, null, 1],
          mb: [2, null, 0],
          pr: [0, 0, 3],
        }}
      >
        The project
      </Copy>
      <div sx={{ flex: [null, null, 3], pl: [0, 0, 3] }}>
        <Copy hasHTML sx={{ maxWidth, mb: 3 }}>
          {project.summary}
        </Copy>
        <Copy>
          <ButtonLink
            color={project.color}
            href={project.cta.url}
            sx={{ fontWeight: "extraBold" }}
            target="_blank"
          >
            {project.cta.label}
          </ButtonLink>
        </Copy>
      </div>
    </Flex>
  );
};

export default ProjectSummary;
